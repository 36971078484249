export default {
  selectors: {
    button: '.cmp-accordion__button',
    panel: '.cmp-accordion__panel',
    item: '.cmp-accordion__item',
    image: '.cmp-accordion__image',
    listItemWrapper: '.batcom-linklist__wrapper-item',
    meganavStyle: 'batcom-accordion--meganav',
  },
  classes: {
    buttonExpanded: 'cmp-accordion__button--expanded',
    panelExpanded: 'cmp-accordion__panel--expanded',
    panelHidden: 'cmp-accordion__panel--hidden',
    hidden: 'batcom-hide',
    noImage: 'batcom-linklist__wrapper-item--no-image',
  },
};
