import { register } from '@netcentric/component-loader';
import { isOnMobile, getURLHash } from '../../../commons/utils';
import config from './batcom-accordion.config';

class Accordion {
  constructor(el, params) {
    this.el = el;
    this.config = { ...config, ...params };
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.debounce;
    this.init();
  }

  init() {
    this.setRefs();
    this.handleTitleLink();
    this.setEvenListeners();
    this.checkImage();
  }

  setRefs() {
    this.buttons = this.el.querySelectorAll(this.selectors.button);
    this.panels = this.el.querySelectorAll(this.selectors.panel);
    this.images = this.el.querySelectorAll(this.selectors.image);
  }

  // Accordion dialog is extended with Link field, so we need to handle the click event on the title link 
  handleTitleLink() { 
    // Prevent first level link to open accordion
    this.buttons.forEach(button => {
      if (button.querySelector('a')) {
        const acordionItem = button.parentElement.parentElement;
       
        // remove the event listeners from acordionItem
        // eslint-disable-next-line no-self-assign
        acordionItem.innerHTML = acordionItem.innerHTML;
        
        // Handle accrdion open/close with icon plus/minus instead of clicking on the title or whole item
        const plusIcon = acordionItem.querySelector('.cmp-accordion__icon');
        
        plusIcon.addEventListener('click', () => { 
          this.openPanelManually(acordionItem);
        });
      }
    });
  }

  openPanelManually(acordionItem) {
    const button = acordionItem.querySelector('.cmp-accordion__button');
    button.classList.toggle('cmp-accordion__button--expanded');
  
    const panel = acordionItem.querySelector('.cmp-accordion__panel');
    panel.classList.toggle('cmp-accordion__panel--expanded');
  
    // set attributes
    if (panel.classList.contains('cmp-accordion__panel--expanded')) {
      button.setAttribute('aria-expanded', 'true');
      acordionItem.setAttribute('data-cmp-expanded', 'true');  
      panel.setAttribute('aria-hidden', 'false');
    } else {
      button.setAttribute('aria-expanded', 'false');
      acordionItem.removeAttribute('data-cmp-expanded');
      panel.setAttribute('aria-hidden', 'true');
    }
  }

  setEvenListeners() {
    window.addEventListener('load', () => {
      if (!this.config.expandedOnMobile && isOnMobile()) {
        this.closePanels();
      }

      this.handleAnchorLink();
    });

    window.addEventListener('hashchange', () => {
      this.handleAnchorLink();
    });

    if (this.config.isAnimationEnabled) {
      window.addEventListener('resize', () => this.onWindowResize());

      this.panels.forEach(element => {
        this.observeClassChange(element);
      });
    }
  }

  /**
   * We take a look at the class attribute of the panel element to determine if
   * the panel is expanded or not. If it is expanded, we set the max-height of
   * the panel to the scrollHeight of the panel to allow the panel to expand with
   * an animation.
   */
  observeClassChange(element) {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (element.classList.contains(this.classes.panelExpanded)) {
            element.style.maxHeight = `${element.scrollHeight}px`;
          } else {
            element.style.maxHeight = null;
          }
        }
      });
    });

    observer.observe(element, {
      attributes: true,
    });
  }

  /**
   * When the window is resized we will potentially need to adjust the
   * opened panels max-height to allow for the content to be always
   * fully visible.
   */
  onWindowResize() {
    clearTimeout(this.debounce);
    delete this.debounce;

    this.debounce = setTimeout(() => {
      this.panels.forEach(panel => {
        if (panel.classList.contains(this.classes.panelExpanded)) {
          panel.style.maxHeight = `${panel.scrollHeight}px`;
        }
      });
    }, 100);
  }

  checkImage() {
    if (this.el.parentElement.classList.contains(this.selectors.meganavStyle)) {
      this.images?.forEach((img) => {
        if (!img.getAttribute('src')) {
          img.classList.add(this.classes.hidden);
          const accordionContainer = img.closest(this.selectors.item);
          const listItemWrappers = accordionContainer.querySelectorAll(this.selectors.listItemWrapper);
          listItemWrappers?.forEach((wrapper) => {
            wrapper.classList.add(this.classes.noImage);
          });
        }
      });
    }
  }

  closePanels() {
    this.buttons.forEach(button => {
      if (button.classList.contains(this.classes.buttonExpanded)) {
        button.classList.remove(this.classes.buttonExpanded);
        button.setAttribute('aria-expanded', 'false');
      }
    });

    this.panels.forEach(panel => {
      if (panel.classList.contains(this.classes.panelExpanded)) {
        const item = panel.parentElement;
        panel.classList.remove(this.classes.panelExpanded);
        panel.classList.add(this.classes.panelHidden);
        panel.setAttribute('aria-hidden', 'true');
        item.removeAttribute('data-cmp-expanded');
      }
    });
  }

  handleAnchorLink() {
    const urlHash = getURLHash();

    if (urlHash) {
      this.openPanel(urlHash);
    }
  }

  openPanel(id) {
    const el = this.el.querySelector(id);
    if (el) {
      const item = el.closest(this.selectors.item);
      const itemButton = item.querySelector(this.selectors.button);
      itemButton.click();
      const itemButtonTop = itemButton.offsetTop;
      setTimeout(window.scrollTo(0, itemButtonTop), 50);
    }
  }
}

register({ Accordion });
